<template>
  <div class="d-flex align-center gap-3">
    <div class="new-price" v-if="priceNew !== '0.00'">
      <span v-if="sign">{{sign}}</span>{{priceNew}}
    </div>
    <div class="new-price" v-if="showTotalSum > 0 && priceNew === '0.00'">
      <span v-if="sign">{{sign}}</span>{{priceNew}}
    </div>
    <div v-if="discountAmmount" class="price-discount-label d-flex align-center"><p class="mb-0">{{Math.round(discountAmmount)}}% off</p></div>
  </div>
</template>

<script>
export default {
  name: 'DiscountsAttraction',
  props: {
    priceNew: {
      type: String,
      default: null
    },
    discountAmmount: {
      type: Number,
      default: null
    },
    calcPrice: {
      type: Boolean,
      default: false
    },
    sign: {
      type: String,
      default: null
    },
    showTotalSum: {
      type:  Number,
      default: null,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.gap-3 {
  gap: 12px;
}
.new-price {
  @include screen('mobile') {
    font-size: 20px;
    line-height: 110%;
  }
}
.price-discount {}
.price-discount-label {
  background-color: #97D334;
  border-radius: 20px;
  padding: 2px 12px;
  width: 100%;
  white-space: nowrap;
  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;

    @include screen('mobile') {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>
