<template>
  <div class="error-container page-container">
    <div v-if="error.statusCode === 404">
      <div>
        <img class="error-img" src="/img/404.png" alt="404 page">
      </div>
      <div class="error-code">404</div>
      <h1 class="error-title">Page Not Found</h1>
    </div>
    <div v-if="error.statusCode === 500">
      <div>
        <img class="error-img" src="/img/500.svg" alt="404 page">
      </div>
      <h1 class="error-title--500">500 Internal Server Error</h1>
      <p class="error-message">
        {{error.message}}
      </p>
    </div>
    <h1 class="error-title" v-if="error.statusCode !== 404 && error.statusCode !== 500">{{error.message}}</h1>
    <v-btn to="/" v-if="error.statusCode !== 500" depressed outlined color="primary">Back to home page</v-btn>
  </div>
</template>

<script>
export default {
  props: ['error'],
}
</script>

<style scoped lang="scss">
.v-application {
  min-height: 670px;
}

.error-container {
  min-height: 600px;
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.error-img {
  max-width: 100%;
  max-height: 50vh;
}

.error-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-transform: capitalize;
  color: #1C1C1C;

  &--500 {
    margin-top: 60px;
    font-size: 23px;
    line-height: 34px;
    color: #424D63;
  }
}

.error-message {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #424D63;
}

.error-code {
  font-weight: bold;
  font-size: 80px;
  line-height: 109px;
  color: #09B5FF;
}
</style>
