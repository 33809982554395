<template>
  <div class="discount-coupon">
    <h4 class="discount-coupon_title">Discount coupon:</h4>
    <div class="discount-coupon-form">
      <div class="discount-coupon-input">
        <v-text-field hide-details filled type="text" v-model.trim="couponCode" @input="changedCouponCode"
          class="coupon-input"
          placeholder="Enter discount code">
          <template #prepend-inner>
            <div>
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_9616_36920)">
                <path d="M9.02667 14.0001L2.5 7.47339V8.80672C2.5 9.16006 2.64 9.50006 2.89333 9.74672L8.08667 14.9401C8.60667 15.4601 9.45333 15.4601 9.97333 14.9401L14.1133 10.8001C14.6333 10.2801 14.6333 9.43339 14.1133 8.91339L9.02667 14.0001Z" fill="#08B5FF"/>
                <path d="M8.08667 11.6067C8.60667 12.1267 9.45333 12.1267 9.97333 11.6067L14.1133 7.46667C14.6333 6.94667 14.6333 6.1 14.1133 5.58L8.92 0.386667C8.66676 0.139535 8.32717 0.000830218 7.97333 0L3.83333 0C3.1 0 2.5 0.6 2.5 1.33333V5.47333C2.5 5.82667 2.64 6.16667 2.89333 6.41333L8.08667 11.6067ZM5.33333 2C5.55435 2 5.76631 2.0878 5.92259 2.24408C6.07887 2.40036 6.16667 2.61232 6.16667 2.83333C6.16667 3.05435 6.07887 3.26631 5.92259 3.42259C5.76631 3.57887 5.55435 3.66667 5.33333 3.66667C5.11232 3.66667 4.90036 3.57887 4.74408 3.42259C4.5878 3.26631 4.5 3.05435 4.5 2.83333C4.5 2.61232 4.5878 2.40036 4.74408 2.24408C4.90036 2.0878 5.11232 2 5.33333 2Z" fill="#08B5FF"/>
                </g>
                <defs>
                <clipPath id="clip0_9616_36920">
                <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
                </clipPath>
                </defs>
              </svg>
            </div>
          </template>
        </v-text-field>
      </div>
      <div class="discount-coupon-errors" v-if="hasError">
        <span v-for="(error, index) in errorMessages" :key="index">{{ error.detail }}</span>
      </div>
      <v-container class="pl-0 pr-0" v-if="cartData && cartData.discounts">
        <div class="pb-4" v-for="(discount, index) in cartData.discounts" :key="index">
          <div class="discount-coupon-remove d-flex align-center ">Coupon:
            <b class="ml-2 text-uppercase">{{ discount.coupon_code }}</b>
            <div v-if="discount.absolute_value > 0" class="ml-auto">
              <span>-{{ currentCurrencySign }}</span>
              <span>{{ discount.absolute_value }}</span>
              <span class="text--small">{{ currentCurrencyCode }}</span>
            </div>
            <div v-else class="ml-auto"><span>{{ currentCurrencySign }}</span>0</div>
            <svg @click="cancelDiscount(discount.id)" class="cursor-pointer ml-4" width="11" height="11" viewBox="0 0 11 11"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.5007 7.30796L9.061 10.7386C9.41817 11.0854 9.65055 11.0889 10.0142 10.7386L10.7286 10.0501C11.0785 9.713 11.1022 9.49171 10.7286 9.13164L6.95926 5.50014L10.7289 1.86864C11.082 1.52733 11.0888 1.29698 10.7289 0.949847L10.0146 0.261723C9.64402 -0.0954419 9.41507 -0.0789424 9.06135 0.261723L5.5007 3.69232L1.94039 0.262046C1.58667 -0.078619 1.35772 -0.0951184 0.987153 0.262046L0.272823 0.950171C-0.0874352 1.29731 -0.0809039 1.52765 0.272823 1.86896L4.04213 5.50014L0.272823 9.13164C-0.100842 9.49171 -0.0809039 9.713 0.272823 10.0501L0.986809 10.7386C1.34741 11.0889 1.57979 11.0854 1.94005 10.7386L5.5007 7.30796Z"
                fill="#08B5FF" />
            </svg>
          </div>
        </div>
      </v-container>

      <div class="discount-coupon-activate">
        <!-- :disabled="!couponCode.length"-->
        <v-btn class="discount-coupon-btn" color="#000" large @click="applyDiscount" block>
          Apply
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Discount from '@/mixins/discount.js'

export default {
  mixins: [Discount],
  props: ['inSidebar'],
  computed: {
    cartData() {
      return this.$store.state.cart
    },
    currentCurrencySign() {
      return this.$store.state.currency.sign
    },
    currentCurrencyCode() {
      return this.$store.state.currency.code
    }
  },
  methods: {
    applyDiscount() {
      this.errorMessages = []
      this.$axios.$post('/api/order/v2/cart/discount', { coupon_code: this.couponCode }).then(res => {
        this.$store.dispatch('getCart')
        this.couponCode = ''
      }).catch(error => {
        this.handleDiscountErrors(error)
        this.$store.dispatch('getCart')
      })
    },
    cancelDiscount(id) {
      this.$axios.$delete(`/api/order/v1/cart/discount/${id}`).then(res => {
        this.$store.dispatch('getCart')
        this.errorMessages = []
      }).catch(error => {
        this.handleDiscountErrors(error)
      })
    },
    changedCouponCode() {
      this.errorMessages = []
    }
  }
}
</script>

<style src="./discount.scss" lang="scss">
</style>
