<template>
  <div class="empty-results">
    <div v-if="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) && isAttractions" class="result-icon-filter">
      <v-dialog
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="lighten-2"
            white
            v-bind="attrs"
            v-on="on"
            @click="toggleDialog"
          >
            <img src="/img/icon/filter-slider.svg" alt="icon">
          </v-btn>
        </template>

        <v-card class="result-icon-filter__dialog">
          <v-card-title class="text-h5 lighten-2 d-flex justify-space-between">
            Filters
            <svg class="cursor-pointer" @click="toggleDialog" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0.8125C4.64844 0.8125 0.3125 5.14844 0.3125 10.5C0.3125 15.8516 4.64844 20.1875 10 20.1875C15.3516 20.1875 19.6875 15.8516 19.6875 10.5C19.6875 5.14844 15.3516 0.8125 10 0.8125ZM10 18.3125C5.66406 18.3125 2.1875 14.8359 2.1875 10.5C2.1875 6.20312 5.66406 2.6875 10 2.6875C14.2969 2.6875 17.8125 6.20312 17.8125 10.5C17.8125 14.8359 14.2969 18.3125 10 18.3125ZM13.9453 8.07812C14.1406 7.92188 14.1406 7.60938 13.9453 7.41406L13.0859 6.55469C12.8906 6.35938 12.5781 6.35938 12.4219 6.55469L10 8.97656L7.53906 6.55469C7.38281 6.35938 7.07031 6.35938 6.875 6.55469L6.01562 7.41406C5.82031 7.60938 5.82031 7.92188 6.01562 8.07812L8.4375 10.5L6.01562 12.9609C5.82031 13.1172 5.82031 13.4297 6.01562 13.625L6.875 14.4844C7.07031 14.6797 7.38281 14.6797 7.53906 14.4844L10 12.0625L12.4219 14.4844C12.5781 14.6797 12.8906 14.6797 13.0859 14.4844L13.9453 13.625C14.1406 13.4297 14.1406 13.1172 13.9453 12.9609L11.5234 10.5L13.9453 8.07812Z" fill="#424D63"/>
            </svg>
          </v-card-title>
          <filter-panel @close="closeDialog"/>
        </v-card>
      </v-dialog>
    </div>
    <h4 class="empty-results_title">{{title}}</h4>
    <p class="empty-results_message">{{info}}</p>
  </div>
</template>

<script>
import FilterPanel from '@/components/FilterPanel/FilterPanel'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      closeDialog: false
    }
  },
  computed: {
    hotelsProducts() {
      return this.$store.state.hotelsProducts
    },
    isAttractions() {
      return this.$route.fullPath.indexOf('attractions') > -1
    }
  },
  beforeDestroy(){
    const html = document.getElementsByTagName('html')[0]
    html.style.overflowY = null
  },
  methods: {
    toggleDialog(){
      const html = document.getElementsByTagName('html')[0]
      this.dialog = !this.dialog
      if (this.dialog) {
        html.style.overflowY = 'hidden'
      } else {
        html.style.overflowY = null
      }
    },
  },
  components: {
    FilterPanel
  },
}
</script>

<style src="./empty-results.css"></style>
